import { Link, graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import logo from "../images/logo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"

export default function Navbar() {
  const data = useStaticQuery(graphql`
    query SiteInfo {
      site {
        siteMetadata {
          portalUrl
        }
      }
    }
  `)
  const { portalUrl } = data.site.siteMetadata
  const [showNav, setShowNav] = useState(false)

  const handleClick = () => {
    setShowNav(!showNav)
  }

  return (
    <header>
      <nav className="site-nav grid layout">
        <Link className="nav-logo" to="/">
          <img src={logo} alt="logo" />
        </Link>
        <div onClick={handleClick} className="nav-icon">
          <FontAwesomeIcon icon={faBars} />
        </div>
        <div
          className={
            showNav
              ? "nav-links show-nav nav-links-mobile"
              : "nav-links not-show-nav"
          }
        >
          <Link to="/">Inicio</Link>
          <Link to="/courses">Cursos</Link>
          <Link to="/blogs">Blog</Link>
          <Link to="/contact">Contacto</Link>
          <a
            href={portalUrl}
            className={showNav ? "nav-button-mobile" : "nav-button"}
          >
            Acceder
          </a>
        </div>
      </nav>
    </header>
  )
}
